import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import "date-fns";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { formatPostCode, formatAddress } from "common-utils/utils/formatter";
import { defaultCountry } from "common-utils/utils/country";
import { isPostcodeValid } from "common-utils/utils/validation";
import { RadioGroup } from "@mui/material";
import Radio from "@mui/material/Radio";
import { MuiTelInput } from "mui-tel-input";
import { Box } from "@mui/material";
import { useOutletContext } from "react-router-dom";

import {
  OrderMap,
  GoogleAutoCompleteAddress,
} from "@shared/components/lib/index";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQuery, useMutation } from "react-query";
import { zod } from "common-utils";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const { OrderDelivery: zodSchema } = zod;
import { postRequestUI, getRequestUI } from "common-utils/utils/api";
import {
  IconLetter,
  IconPickUp,
  IconPickUpRound,
  IconDropOf,
  IconBackpack,
  IconCargobike,
  IconCheckmark,
  IconVan,
  RetailerInsurances,
  ShippingLabelButton,
} from "@shared/components/lib/index";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { usePreviousProps } from "common-utils/hooks/usePreviousProps";
import useTheme from "@mui/material/styles/useTheme";
const colorGrey = "#7F8FA4";
const useStyles = (theme) => ({
  scrollableContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "auto",
  },

  containerItems: {
    position: "relative",
    zIndex: 100,
    top: 0,
    backgroundColor: "#fff",
    flexGrow: 1,
    marginLeft: "50px",
    [theme.breakpoints.up("sm")]: {
      zIndex: 100,
      top: 0,
      flexGrow: 1,
      maxWidth: 750,
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      borderRadius: "8px",
      marginBottom: theme.spacing(4),
    },
  },

  containerItem: {
    padding: theme.spacing(5),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      top: 85,
      paddingTop: "20px",
    },
    [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  containerRequestedItem: {
    padding: "15px",
    [theme.breakpoints.up("sm")]: {
      padding: "15px",
    },
    width: "50%",
    justifyContent: "flex-start",
  },

  errorBlock: {
    backgroundColor: "rgba(255, 0, 0, 0.1)",
    color: "rgba(255, 0, 0, 1)",
    padding: theme.spacing(2),
    borderRadius: "4px",
    margin: theme.spacing(1, 0),
    border: "1px solid rgba(255, 0, 0, 0.3)",
    fontSize: "14px",
  },
  circleProgress: {
    color: "#fff",
  },
  circleProgress2: {
    color: "#7F8FA4",
  },
  productStyle: {
    width: "32%",
    borderRadius: 1,
    padding: "10px 13px",
    border: "1px solid" + colorGrey,
    color: "#000",
    cursor: "pointer",
    marginBottom: 4,
    [theme.breakpoints.down("xs")]: {
      padding: 7,
    },
  },
  activeProductStyle: {
    width: "32%",
    borderRadius: 1,
    padding: "10px 13px",
    border: "1px solid #0635C9",
    backgroundColor: "#0635C9",
    color: "#fff",
    cursor: "pointer",
    marginBottom: 4,
    [theme.breakpoints.down("xs")]: {
      padding: 8,
    },
  },
  typeOfDeliveryStyle: {
    width: "32%",
    borderRadius: 1,
    padding: "10px 15px",
    border: "1px solid" + colorGrey,
    color: "#000",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      padding: 8,
    },
  },
  activeTypeOfDeliveryStyle: {
    width: "32%",
    borderRadius: 1,
    padding: "10 15",
    border: "1px solid #0635C9",
    backgroundColor: "#0635C9",
    color: "#fff",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      padding: 8,
    },
  },
  buttonSubmit: {
    "&.MuiButton-root": {
      marginTop: "5",
      marginBottom: "10",
      marginLeft: "50px",
      borderRadius: "5px",
      width: "75%",
      color: "#fff",
      backgroundColor: "#0635C9",
      fontFamily: "Nunito, sans-serif",
      fontWeight: "bold",
      textTransform: "none",
      "&:disabled": {
        backgroundColor: `${colorGrey}`,
      },
    },
  },

  labelRoot: {
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  rightContainerStyle: {
    position: "sticky",
    zIndex: 1000,
    alignSelf: "flex-start",
    maxHeight: "calc(100vh - 20px)",
    overflowY: "auto",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(3),
    },
  },

  summaryBlockStyle: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: "16px",
    marginTop: "0px",
  },

  summaryTitleStyle: {
    fontWeight: "600",
    marginBottom: "12px",
    color: "#333",
    fontSize: "18px",
  },

  summaryStepStyle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    color: "#333",
    fontSize: "14px",
  },

  summaryStepIconStyle: {
    marginRight: "8px",
  },
});
function NewDeliveryOrder() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { userClaims } = useOutletContext() || {};

  const [newOrder, setNewOrder] = useState({
    selectedLocation: undefined,
    products: [],
    selectedProductId: undefined,
    selectedSizeId: undefined,
    sizes: [],
    availableCountries: [],
    selectedCountry: undefined,
    showGoogleAutocomplete: false,
    recipientNumberError: undefined,
    address: {
      city: undefined,
      street: undefined,
      houseAdditions: [],
      houseAddition: undefined,
      houseNumber: undefined,
      postcode: undefined,
      dropOfCoordinates: undefined,
    },
    contact: {
      recipientEmail: undefined,
      recipientName: undefined,
      recipientNumber: undefined,
    },
    extras: {
      externalId: undefined,
      description: undefined,
    },
    options: {
      signature: false,
      deliverToNeighbours: false,
      secondFactor: false,
      selectedIncurance: undefined,
    },
    createdDelivery: undefined,
  });

  const [createdOrder, setCreatedOrder] = useState({});
  const [resolvedAddress, setResolvedAddress] = useState(undefined);

  const {
    register,
    handleSubmit,
    control,
    resetField,
    reset,
    formState: { errors, isValid },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    resolver: zodResolver(zodSchema),
    defaultValues: {
      contact: {
        recipientName: undefined,
        recipientEmail: undefined,
        recipientNumber: undefined,
      },
      address: {
        city: undefined,
        street: undefined,
        postcode: undefined,
        houseAdditions: [],
        houseAddition: undefined,
        houseNumber: undefined,
        dropOfCoordinates: undefined,
      },
      selectedSizeId: undefined,
      selectedProductId: undefined,
    },
    mode: "onChange",
  });

  const stepStatus = {
    pickup: !!newOrder.selectedLocation,
    deliveryTo: !!newOrder.address.street || !!resolvedAddress,
    size: !!newOrder.selectedSizeId,
    deliveryOptions: !!newOrder.selectedProductId,
    recipientContact: !!(
      newOrder.contact.recipientEmail &&
      !errors.contact?.recipientEmail &&
      newOrder.contact.recipientName &&
      !errors.contact?.recipientName
    ),
  };

  const areSomeStepsInvalid = Object.values(stepStatus).some((item) => !item);

  const DeliverySummary = ({ stepsStatus }) => {
    const checkmarkIcon = (isCompleted) => (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: "8px" }}
      >
        <circle
          cx="8"
          cy="8"
          r="7.5"
          fill={isCompleted ? "#10C16C" : "#CCC"}
          stroke={isCompleted ? "#10C16C" : "#CCC"}
        />
        {isCompleted && (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.66675 8.93934L5.35355 7.62614L4.64648 8.33321L6.66675 10.3535L11.6667 5.35355L10.9597 4.64648L6.66675 8.93934Z"
            fill="white"
          />
        )}
      </svg>
    );

    return (
      <div style={styles.summaryBlockStyle}>
        <div style={styles.summaryTitleStyle}>Delivery Summary</div>
        <div style={styles.summaryStepStyle}>
          {checkmarkIcon(stepStatus.pickup)}Pick up
        </div>
        <div style={styles.summaryStepStyle}>
          {checkmarkIcon(stepStatus.deliveryTo)}Delivery to
        </div>
        <div style={styles.summaryStepStyle}>
          {checkmarkIcon(stepStatus.size)}Package size
        </div>
        <div style={styles.summaryStepStyle}>
          {checkmarkIcon(stepStatus.deliveryOptions)}Delivery options
        </div>
        <div style={styles.summaryStepStyle}>
          {checkmarkIcon(stepStatus.recipientContact)}Receiver contact
        </div>
      </div>
    );
  };

  const {
    data: prerequisitesData,
    isLoading,
    error,
  } = useQuery(
    ["order-prerequisites"],
    async () => {
      const result = await getRequestUI("/order-prerequisites");
      return result;
    },
    {
      onError: (err) => {
        console.error(err.message);
      },
      onSuccess: (data) => {
        if (data.locations.length >= 1) {
          const defaultLocation =
            data.locations.find((location) => location.default) ||
            data.locations[0];
          if (defaultLocation) {
            setNewOrder((prev) => ({
              ...prev,
              selectedLocation: defaultLocation,
            }));
          }
        }
      },
      enabled: !newOrder.selectedLocation,
    },
  );

  const {
    isLoading: availableCountriesLoading,
    error: availableCountriesError,
  } = useQuery(
    ["available-countries", newOrder.selectedLocation?.id],
    async () => {
      if (newOrder.selectedLocation?.id) {
        const result = await getRequestUI(
          `/retailer/available-countries/${newOrder.selectedLocation.id}`,
        );
        return result;
      }
      return [];
    },
    {
      onSuccess: (data) => {
        setNewOrder((prev) => ({
          ...prev,
          availableCountries: data,
          selectedCountry: getDefaultSelectedCountry(
            data,
            newOrder.selectedLocation,
          ),
        }));
      },
      enabled: !!newOrder.selectedLocation?.id,
      refetchOnWindowFocus: false,
    },
  );
  const {
    data: sizesData,
    isLoading: sizesLoading,
    error: sizesError,
  } = useQuery(
    ["sizes", newOrder.selectedLocation?.id],
    async () => {
      if (newOrder.selectedLocation?.id) {
        const res = await getRequestUI(
          `/retailer/sizes/${newOrder.selectedLocation.id}`,
        );
        return res;
      }
      return [];
    },
    {
      onSuccess: (data) => {
        setNewOrder((prev) => ({
          ...prev,
          sizes: data,
          selectedSizeId: newOrder.selectedSizeId,
        }));
      },
      enabled: !!newOrder.selectedLocation?.id,
      refetchOnWindowFocus: false,
    },
  );

  const queryParameters = React.useMemo(
    () => ({
      sizeId: newOrder.selectedSizeId?.id,
      locationId: newOrder.selectedLocation?.id,
      deliveryAddress: {
        ...resolvedAddress,
        address: {
          ...resolvedAddress?.address,
          houseNumber: newOrder.address.houseNumber,
          houseAddition: newOrder.address.houseAddition,
          postcode: newOrder.address.postcode,
        },
      },
    }),
    [
      newOrder.selectedSizeId?.id,
      newOrder.selectedLocation?.id,
      resolvedAddress,
      newOrder.address,
    ],
  );

  const {
    data: productsData,
    isLoading: productsLoading,
    error: productsError,
  } = useQuery(
    ["products", queryParameters],
    async () => {
      const data = {
        sizeId: newOrder.selectedSizeId.id,
        locationId: newOrder.selectedLocation.id,
        deliveryAddress: queryParameters.deliveryAddress,
      };
      return await postRequestUI("/retailer-capabilities", data);
    },
    {
      onSuccess: (data) => {
        setNewOrder((prev) => ({
          ...prev,
          products: data,
        }));
      },
      onError: (error) => {
        console.error("Error loading products:", error);
      },
      enabled:
        !!newOrder.selectedLocation &&
        !!newOrder.selectedSizeId &&
        !!resolvedAddress,
      refetchOnWindowFocus: false,
    },
  );

  const getDefaultSelectedCountry = (availableCountries, selectedLocation) => {
    if (newOrder.selectedCountry) {
      return newOrder.selectedCountry;
    }
    const defaultCountry = availableCountries?.find(
      (country) => country.code === "nl",
    );
    if (defaultCountry) {
      return defaultCountry;
    } else if (selectedLocation?.address?.country) {
      return selectedLocation.address.country;
    } else {
      return undefined;
    }
  };

  const fetchAddressData = async (postcode, houseNumber, getRequestUI) => {
    const normalizedPostcode = formatPostCode(postcode);
    const addressQueryValid =
      isPostcodeValid(normalizedPostcode) && parseInt(houseNumber);
    if (!addressQueryValid) {
      setError("address.postcode", {
        type: "custom",
        message: "Invalid Postcode",
      });
      return undefined;
    }
    clearErrors("address.postcode");
    const result = await getRequestUI("/retailer/house/additions", {
      postcode: normalizedPostcode,
      houseNumber,
    });
    return result;
  };

  const debouncedFetchAddressData = useRef(
    _.debounce((postcode, houseNumber, getRequestUI, onSuccess, onError) => {
      fetchAddressData(postcode, houseNumber, getRequestUI)
        .then(onSuccess)
        .catch(onError);
    }, 300),
  ).current;

  const {
    data: addressData,
    isLoading: addressLoading,
    error: addressError,
  } = useQuery(
    ["address", newOrder.address.postcode, newOrder.address.houseNumber],
    () =>
      fetchAddressData(
        newOrder.address.postcode,
        newOrder.address.houseNumber,
        getRequestUI,
      ),
    {
      enabled: false,
      onSuccess: (data) => {
        if (!data) {
          setNewOrder((prev) => ({
            ...prev,
            showGoogleAutocomplete: true,
            address: {
              ...prev.address,
              city: "",
              street: "",
            },
          }));

          return;
        }
        const coordinates = {
          lat: data.coordinates.latitude,
          lng: data.coordinates.longitude,
        };
        setNewOrder((prev) => ({
          ...prev,
          showGoogleAutocomplete: false,
          address: {
            ...prev.address,
            dropOfCoordinates: coordinates,
            street: data.street,
            city: data.city,
            houseAdditions: data.houseAdditions,
          },
        }));
        setResolvedAddress({
          address: {
            country: newOrder.selectedCountry,
            postcode: newOrder.address.postcode,
            houseNumber: newOrder.address.houseNumber,
            houseAddition: newOrder.address.houseAddition,
          },
          street: data.street,
          coordinates: data.coordinates,
          city: data.city,
        });
      },
      onError: () => {
        setNewOrder((prev) => ({
          ...prev,
          address: {
            ...prev.address,
            houseAdditions: [],
          },
        }));
        setResolvedAddress(undefined);
        setNewOrder((prev) => ({
          ...prev,
          showGoogleAutocomplete: true,
          address: {
            ...prev.address,
            city: "",
            street: "",
          },
        }));
      },
    },
  );

  useEffect(() => {
    if (
      !!newOrder.address.postcode &&
      !!newOrder.address.houseNumber &&
      !newOrder.showGoogleAutocomplete &&
      ((newOrder.selectedLocation &&
        newOrder.selectedLocation.address.country.code === "nl") ||
        newOrder.selectedCountry.code === "nl")
    ) {
      debouncedFetchAddressData(
        newOrder.address.postcode,
        newOrder.address.houseNumber,
        getRequestUI,
        (data) => {
          if (!data) {
            setNewOrder((prev) => ({
              ...prev,
              address: {
                ...prev.address,
                city: "",
                street: "",
              },
            }));
            return;
          }
          const coordinates = {
            lat: data.coordinates.latitude,
            lng: data.coordinates.longitude,
          };
          setNewOrder((prev) => ({
            ...prev,
            address: {
              ...prev.address,
              dropOfCoordinates: coordinates,
              street: data.street,
              city: data.city,
              houseAdditions: data.houseAdditions,
            },
          }));
          setResolvedAddress({
            address: {
              country: newOrder.selectedCountry,
              postcode: newOrder.address.postcode,
              houseNumber: newOrder.address.houseNumber,
              houseAddition: newOrder.address.houseAddition,
            },
            street: data.street,
            coordinates: data.coordinates,
            city: data.city,
          });
        },
        () => {
          setNewOrder((prev) => ({
            ...prev,
            address: {
              ...prev.address,
              houseAdditions: [],
            },
          }));
          setResolvedAddress(undefined);
          setNewOrder((prev) => ({
            ...prev,
            showGoogleAutocomplete: true,
            address: {
              ...prev.address,
              city: "",
              street: "",
            },
          }));
        },
      );
    }
  }, [
    newOrder.address.postcode,
    newOrder.address.houseNumber,
    newOrder.selectedLocation,
    newOrder.selectedCountry,
    newOrder.showGoogleAutocomplete,
  ]);

  const { mutate: createOrder, isLoading: isCreatingOrder } = useMutation(
    async () => {
      const pickupLocation = {
        id: newOrder.selectedLocation.id,
      };
      const deliveryAddress = {
        ...resolvedAddress,
        address: {
          ...resolvedAddress.address,
          houseNumber: newOrder.address.houseNumber,
          houseAddition: newOrder.address.houseAddition,
          postcode: newOrder.address.postcode,
        },
      };
      if (newOrder.address.houseAddition) {
        deliveryAddress.address.houseAdditions = newOrder.address.houseAddition;
      }
      const payload = {
        product: newOrder.products
          .flat()
          .find((p) => p.id.toString() === newOrder.selectedProductId),
        sizeId: newOrder.selectedSizeId.id,
        description: newOrder.extras.description,
        recipientName: newOrder.contact.recipientName,
        recipientNumber: newOrder.contact.recipientNumber,
        email: newOrder.contact.recipientEmail,
        deliveryAddress: deliveryAddress,
        pickupLocation: pickupLocation,
        signature: newOrder.options.signature,
        deliverToNeighbours: newOrder.options.deliverToNeighbours,
        insuranceId: newOrder.options.selectedIncurance,
        externalId: newOrder.extras.externalId,
        secondFactorVerification: newOrder.options.secondFactor,
      };

      const result = await postRequestUI("/retailer/order", payload);
      return result;
    },
    {
      onSuccess: (result) => {
        const res = result.response;
        setCreatedOrder({
          productName: res.productTitle,
          retailerName: res.retailerName,
          retailerPostcode: newOrder.selectedLocation.address.postcode,
          retailerNumber: newOrder.selectedLocation.personNumber,
          carrierName: "",
          carrierEmail: res.carrierNotificationEmail
            ? res.carrierNotificationEmail
            : res.carrierEmail,
          trackingCode: res.trackingCode,
          postcode: resolvedAddress.address.postcode,
          street: newOrder.address.street,
          houseNumber: resolvedAddress.address.houseNumber,
          houseNumberAdditions: resolvedAddress.address.houseAddition,
          recipientName: newOrder.contact.recipientName,
          recipientNumber: newOrder.contact.recipientNumber,
          email: newOrder.contact.recipientEmail,
        });
        setNewOrder((prev) => ({
          ...prev,
          createdDelivery: {
            status: "success",
            message: "Order placed successfully.",
          },
        }));
        reset();
        setResolvedAddress(undefined);
      },
      onError: (error) => {
        setNewOrder((prev) => ({
          ...prev,
          createdDelivery: { status: "error", message: error.message },
        }));
        const code = error.code;
        const message = error.message;
        if (code === "invalid-argument") {
        }
      },
    },
  );

  const onSubmit = (data) => {
    createOrder();
  };
  const onError = (errors) => {
    console.error("Form errors:", errors);
  };

  const addressReset = () => {
    setResolvedAddress(undefined),
      setNewOrder((prev) => ({
        ...prev,
        selectedProductId: undefined,
        selectedSizeId: undefined,
        showGoogleAutocomplete: false,
        address: {},
      }));
  };

  useEffect(() => {
    cleanForm();
    setNewOrder((prev) => ({
      ...prev,
      createdDelivery: undefined,
    }));
  }, []);

  useEffect(() => {
    if (resolvedAddress && !_.isEmpty(resolvedAddress)) {
      return;
    }
    setNewOrder((prev) => ({
      ...prev,
      products: [],
    }));
  }, [resolvedAddress, newOrder.selectedSizeId]);

  const chooseCountry = (e) => {
    const chosenCountry = newOrder.availableCountries.find(
      (country) => country.code === e.target.value,
    );
    setResolvedAddress(undefined);
    setNewOrder((prev) => ({
      ...prev,
      selectedCountry: chosenCountry,
      selectedSizeId: undefined,
      address: {},
    }));
    clearErrors();
  };

  const chooseLocation = (location) => {
    cleanForm();
    if (location) {
      setNewOrder((prev) => ({
        ...prev,
        selectedLocation: location,
        selectedCountry: undefined,
      }));
      setResolvedAddress(undefined);
    } else {
      setNewOrder((prev) => ({
        ...prev,
        selectedLocation: undefined,
        availableCountries: [],
        sizes: [],
        selectedSizeId: {},
        products: [],
      }));
    }
  };

  const chooseActiveSize = async (size) => {
    setNewOrder((prev) => ({
      ...prev,
      selectedSizeId: size,
    }));
  };

  const chooseActiveInsurance = (e) => {
    setNewOrder((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        selectedIncurance: e.target.value,
      },
    }));
  };

  const handlePostcodeChange = (event) => {
    const formattedPostcode = formatPostCode(event.target.value);
    if (!formattedPostcode) {
      addressReset();
    }
    setNewOrder((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        postcode: formattedPostcode,
        showGoogleAutocomplete: false,
      },
    }));
  };

  const chooseHouseNumber = (val) => {
    if (!val) {
      addressReset();
    } else {
      setNewOrder((prev) => ({
        ...prev,
        showGoogleAutocomplete: false,
        address: {
          ...prev.address,
          houseNumber: val,
        },
      }));
    }
  };

  const chooseHouseAddition = (val) => {
    setNewOrder((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        houseAddition: val,
      },
    }));
  };

  const handleProductChange = (event) => {
    const productId = event.target.value;
    setNewOrder((prev) => ({
      ...prev,
      selectedProductId: productId,
    }));
  };
  const handleRecipientName = (event) => {
    const { value } = event.target;
    setValue("contact.recipientName", value);
    setNewOrder((prev) => ({
      ...prev,
      contact: {
        ...prev.contact,
        recipientName: value,
      },
    }));
  };
  const handleRecipientNumber = (value) => {
    const normalizedNumber = "+" + value.replace(/\D/g, "");
    const phoneRegex = /^\+\d{7,16}$/;

    if (value.trim() === "" || phoneRegex.test(normalizedNumber)) {
      setNewOrder((prev) => ({
        ...prev,
        contact: {
          ...prev.contact,
          recipientNumber: normalizedNumber,
          recipientNumberError: "",
        },
      }));
    } else {
      setNewOrder((prev) => ({
        ...prev,
        contact: {
          ...prev.contact,
          recipientNumber: normalizedNumber,
          recipientNumberError: "Invalid recipient number",
        },
      }));
    }
  };

  const handleRemarksChange = (val) => {
    setNewOrder((prev) => ({
      ...prev,
      extras: {
        ...prev.extras,
        description: val,
      },
    }));
  };

  const handleReferenceNumber = (val) => {
    const trimmedVal = val.trim().replace(/[^a-zA-Z0-9-_]/g, "");
    setNewOrder((prev) => ({
      ...prev,
      extras: {
        ...prev.extras,
        externalId: trimmedVal,
      },
    }));
  };

  const cleanForm = () => {
    reset();
    setNewOrder((prev) => ({
      ...prev,
      selectedSizeId: undefined,
      selectedProductId: undefined,
      setResolvedAddress: undefined,
      showGoogleAutocomplete: false,
      address: {
        ...prev.address,
        city: undefined,
        postcode: undefined,
        street: undefined,
        houseAddition: undefined,
        houseAdditions: [],
        houseNumber: undefined,
        dropOfCoordinates: undefined,
      },
      contact: {
        ...prev.contact,
        recipientEmail: "",
        recipientName: undefined,
        recipientNumber: undefined,
      },
      extras: {
        ...prev.extras,
        externalId: "",
        description: "",
      },
      options: {
        ...prev.options,
        selectedIncurance: undefined,
        description: "",
        signature: false,
        deliverToNeighbours: false,
        secondFactor: false,
      },
      products: [],
    }));
  };

  const onAddressResolved = (autoCompleteData) => {
    if (!autoCompleteData) {
      setResolvedAddress(undefined);
      setNewOrder((prev) => ({
        ...prev,
        selectedSizeId: undefined,
        selectedProductId: undefined,
        address: {
          ...prev.address,
          dropOfCoordinates: undefined,
          street: undefined,
        },
      }));
      return;
    }
    setResolvedAddress(undefined);
    const { coordinates, postcode, houseNumber, city, street, country } =
      autoCompleteData;
    setNewOrder((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        dropOfCoordinates: {
          lat: coordinates.latitude,
          lng: coordinates.longitude,
        },
        postcode,
        city,
        street,
        houseNumber,
      },
    }));
    setResolvedAddress({
      address: {
        country,
        postcode: newOrder.address.postcode,
        houseNumber: newOrder.address.houseNumber,
        houseAddition: newOrder.address.houseAddition,
      },
      street,
      coordinates,
      city,
    });
  };

  const handleSecondFactorChange = (event) => {
    const checked = event.target.checked;
    setNewOrder((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        secondFactor: checked,
      },
    }));
  };
  const handleSignatureChange = (event) => {
    const checked = event.target.checked;
    setNewOrder((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        signature: checked,
      },
    }));
  };

  const handleDeliverToNeighboursChange = (event) => {
    const checked = event.target.checked;
    setNewOrder((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        deliverToNeighbours: checked,
      },
    }));
  };

  const hasTwoFactorAuth = () => {
    if (!productsData || !newOrder.selectedProductId) {
      return false;
    }

    const selectedProduct = productsData.find(
      (product) => product.id.toString() === newOrder.selectedProductId,
    );
    return selectedProduct ? selectedProduct.twoFactorAuth : false;
  };

  const renderIcon = (item, color = "#79797C") => {
    const isSelected = newOrder.selectedSizeId?.id === item;
    switch (item) {
      case "SMALL":
        return <IconLetter color={isSelected ? "#FFFFFF" : color} />;
      case "STANDARD":
        return <IconBackpack color={isSelected ? "#FFFFFF" : color} />;
      case "XL":
        return <IconCargobike color={isSelected ? "#FFFFFF" : color} />;
      case "XXL":
        return <IconVan color={isSelected ? "#FFFFFF" : color} />;
      default:
        return <></>;
    }
  };

  const renderNewDeliveryForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <Grid item sx={styles.containerItem}>
            <Grid container alignItems="center">
              <Grid item>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 700,
                    paddingRight: theme.spacing(2),
                  }}
                >
                  Pick up from
                </div>
              </Grid>
              {userClaims.userType === "retailer" ? (
                <Grid
                  item
                  style={{
                    width: isMobile ? "100%" : "78%",
                    marginLeft: isMobile ? "0px" : "10px",
                  }}
                >
                  <TextField
                    data-testid="location_field"
                    id="location"
                    select
                    style={{
                      marginTop: theme.spacing(1),
                      marginBottom: theme.spacing(1),
                    }}
                    fullWidth
                    variant="outlined"
                    value={newOrder.selectedLocation || ""}
                    {...register("selectedLocation")}
                    onChange={async (e) => await chooseLocation(e.target.value)}
                  >
                    {prerequisitesData?.locations.map((location) => (
                      <MenuItem value={location} key={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : (
                <Grid>
                  <Typography>
                    {newOrder.selectedLocation
                      ? newOrder.selectedLocation.name
                      : ""}
                  </Typography>
                  <Typography>
                    {newOrder.selectedLocation
                      ? `${
                          newOrder.selectedLocation.address.city
                        }, ${formatAddress(
                          newOrder.selectedLocation.address.postcode,
                          newOrder.selectedLocation.address.houseNumber,
                          newOrder.selectedLocation.address.houseAdditions,
                          newOrder.selectedLocation.address.street,
                        )}`
                      : ""}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid>
              <Grid container alignItems="center">
                <Grid item>
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: 700,
                      marginBottom: theme.spacing(2),
                      paddingRight: theme.spacing(2),
                    }}
                  >
                    Delivery to
                  </div>
                </Grid>
              </Grid>
              {newOrder.availableCountries.length > 0 && (
                <Grid item xs={12}>
                  <TextField
                    data-testid="country_field"
                    id="country"
                    style={{ marginBottom: theme.spacing(2) }}
                    select
                    fullWidth
                    variant="outlined"
                    value={newOrder.selectedCountry?.code || ""}
                    onChange={chooseCountry}
                  >
                    {newOrder.availableCountries.map((country) => (
                      <MenuItem value={country.code} key={country.code}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              {newOrder.selectedCountry?.code === "nl" ||
              !newOrder.selectedCountry?.code ? (
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ width: "50%" }}>
                      <Typography style={{ fontWeight: "bold", fontSize: 13 }}>
                        Postcode
                      </Typography>
                      <TextField
                        data-testid="postcode_field"
                        id="postcode"
                        value={newOrder.address.postcode}
                        style={{
                          marginBottom: theme.spacing(2),
                          marginRight: isMobile
                            ? theme.spacing(1)
                            : theme.spacing(0),
                        }}
                        error={!!errors.address?.postcode}
                        helperText={errors.address?.postcode?.message}
                        onChange={handlePostcodeChange}
                      />
                    </div>

                    <div style={{ width: "50%" }}>
                      <Typography style={{ fontWeight: "bold", fontSize: 13 }}>
                        House Num
                      </Typography>
                      <TextField
                        data-testid="house_number_field"
                        id="house-number-field"
                        variant="outlined"
                        value={newOrder.address.houseNumber}
                        style={{
                          marginBottom: theme.spacing(2),
                          marginRight: isMobile
                            ? theme.spacing(1)
                            : theme.spacing(0),
                        }}
                        onChange={(e) => chooseHouseNumber(e.target.value)}
                      />
                    </div>
                    <div
                      style={{ width: "50%", justifyContent: "space-between" }}
                    >
                      {newOrder.address.houseAdditions &&
                        newOrder.address.houseAdditions.length > 0 && (
                          <>
                            <Typography
                              style={{ fontWeight: "bold", fontSize: 13 }}
                            >
                              House Add.
                            </Typography>
                            <TextField
                              variant="outlined"
                              select
                              fullWidth
                              id="house-addition"
                              style={{
                                marginBottom: theme.spacing(2),
                                marginRight: isMobile
                                  ? theme.spacing(1)
                                  : theme.spacing(0),
                              }}
                              onChange={(e) =>
                                chooseHouseAddition(e.target.value)
                              }
                            >
                              {newOrder.address.houseAdditions.map(
                                (addition) => (
                                  <MenuItem value={addition} key={addition}>
                                    {addition}
                                  </MenuItem>
                                ),
                              )}
                            </TextField>
                          </>
                        )}
                    </div>
                  </div>

                  <Grid>
                    {newOrder.showGoogleAutocomplete && (
                      <>
                        <Grid
                          style={{
                            marginBottom: theme.spacing(1),
                            marginTop: theme.spacing(1),
                          }}
                        >
                          {!newOrder.address.street && (
                            <Grid style={styles.errorBlock}>
                              <Typography variant="body1">
                                We are unable to find the address associated
                                with this zip code and house number. If you are
                                sure this is correct, please search for the full
                                address.
                              </Typography>
                            </Grid>
                          )}
                          <GoogleAutoCompleteAddress
                            country={newOrder.selectedCountry || {}}
                            onSelect={onAddressResolved}
                            {...register("street")}
                            address={{
                              city: newOrder.address.city,
                              street: newOrder.address.street,
                              houseNumber: newOrder.address.houseNumber,
                            }}
                          />
                        </Grid>
                      </>
                    )}
                    <Typography style={{ fontWeight: "bold", fontSize: 13 }}>
                      Street
                    </Typography>
                    <TextField
                      id="street-name"
                      variant="outlined"
                      disabled
                      fullWidth
                      value={newOrder.address.street || ""}
                    />
                  </Grid>
                </>
              ) : (
                <GoogleAutoCompleteAddress
                  country={newOrder.selectedCountry}
                  onSelect={onAddressResolved}
                  style={{ position: "relative", zIndex: 1000 }}
                  address={{
                    city: newOrder.address.city,
                    street: newOrder.address.street,
                    houseNumber: newOrder.address.houseNumber,
                  }}
                />
              )}

              <Grid style={{ margin: "20px 0" }}>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 700,
                    marginBottom: "15px",
                  }}
                >
                  Package size
                </div>
                {sizesLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <CircularProgress size={20} style={{ color: "#979797" }} />
                  </div>
                ) : sizesData.length > 0 ? (
                  <Grid item container justifyContent="space-between">
                    {sizesData.map((item, index) => (
                      <Grid
                        key={index}
                        item
                        container
                        alignItems="center"
                        {...register("size")}
                        justifyContent="space-between"
                        sx={
                          newOrder.selectedSizeId?.id === item.id
                            ? styles.activeProductStyle
                            : styles.productStyle
                        }
                        onClick={() => chooseActiveSize(item)}
                      >
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            {renderIcon(
                              item.icon,
                              newOrder.selectedSizeId?.id === item.id
                                ? "#FFFFFF"
                                : "#79797C",
                            )}
                          </Grid>
                          <Grid>
                            <Grid item>
                              <div
                                style={{
                                  marginLeft: theme.spacing(1),
                                  fontSize: "14px",
                                  color:
                                    newOrder.selectedSizeId?.id === item.id
                                      ? "#fff"
                                      : "#000",
                                }}
                              >
                                {item.name}
                              </div>
                              <div
                                style={{
                                  marginLeft: theme.spacing(1),
                                  fontSize: "12px",
                                  color:
                                    newOrder.selectedSizeId?.id === item.id
                                      ? "#fff"
                                      : "#979797",
                                }}
                              >
                                Max.
                              </div>
                              <div
                                style={{
                                  marginLeft: theme.spacing(1),
                                  fontSize: "12px",
                                  color:
                                    newOrder.selectedSizeId?.id === item.id
                                      ? "#fff"
                                      : "#979797",
                                }}
                              >
                                {item.description}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography>No sizes available</Typography>
                )}
              </Grid>
              <Grid style={{ margin: "20px 0" }}>
                {productsLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  ></div>
                ) : newOrder.products.length > 0 ? (
                  <>
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        marginBottom: "10px",
                      }}
                    >
                      Delivery options
                    </div>
                    <Grid
                      item
                      xs={12}
                      style={{
                        borderRadius: "4px",
                        overflow: "hidden",
                        background: "#fff",
                        border: "1px solid #ccc",
                        maxWidth: "66%",
                      }}
                    >
                      <RadioGroup
                        {...register("selectedProductId")}
                        value={newOrder.selectedProductId}
                        onChange={(e) => handleProductChange(e)}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {productsData?.map(
                          (product, index) =>
                            !product.isDisabled && (
                              <FormControlLabel
                                key={product.id}
                                value={product.id}
                                control={
                                  <Radio
                                    color="primary"
                                    disabled={product.isDisabled}
                                  />
                                }
                                label={`${product.name} - ${product.description}`}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0px 0",
                                  backgroundColor: "#E5E5E5",
                                  borderBottom:
                                    index < newOrder.products.length - 1
                                      ? "1px solid #ccc"
                                      : "none",
                                  padding: "0 16px",
                                }}
                              />
                            ),
                        )}
                      </RadioGroup>
                    </Grid>
                  </>
                ) : (
                  <Grid
                    style={{
                      marginBottom: theme.spacing(1),
                      marginTop: theme.spacing(1),
                    }}
                  >
                    <Grid style={styles.errorBlock}>
                      <Typography variant="body1">
                        Delivery is not possible with this delivery address and
                        package size.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid item style={{ marginBottom: theme.spacing(1) }}>
                <div style={{ fontSize: 20, fontWeight: 700 }}>
                  Reciever contact
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", fontSize: 13 }}>
                  Full Name
                </Typography>
                <Controller
                  name="contact.recipientName"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      variant="outlined"
                      data-testid="recipient_name_field"
                      fullWidth
                      value={newOrder.contact.recipientName}
                      error={!!errors.contact?.recipientName}
                      helperText={errors.contact?.recipientName?.message}
                      onChange={(e) => {
                        handleRecipientName(e);
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={{
                  marginBottom: theme.spacing(1),
                  marginTop: theme.spacing(1),
                }}
              >
                {/* recipientEmail Field */}
                <Grid item xs={12} md={6} sx={{ pr: { md: 1, xs: 0 } }}>
                  <Typography style={{ fontWeight: "bold", fontSize: 13 }}>
                    Email:
                  </Typography>
                  <Controller
                    name="contact.recipientEmail"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        data-testid="email_field"
                        id="recipientEmail"
                        variant="outlined"
                        value={value || ""}
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                        onChange={(e) => {
                          const trimmedEmail = e.target.value.trim();
                          onChange(trimmedEmail);
                          setNewOrder((prev) => ({
                            ...prev,
                            contact: {
                              ...prev.contact,
                              recipientEmail: trimmedEmail,
                            },
                          }));
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} sx={{ pl: { md: 1, xs: 0 } }}>
                  <Typography style={{ fontWeight: "bold", fontSize: 13 }}>
                    Phone Number (optional)
                  </Typography>
                  <MuiTelInput
                    defaultCountry={"NL"}
                    data-testid="recipient_number_field"
                    value={newOrder.contact.recipientNumber}
                    onChange={handleRecipientNumber}
                    error={!!newOrder.contact.recipientNumberError}
                    helperText={newOrder.contact.recipientNumberError}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="column">
              {hasTwoFactorAuth() && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newOrder.options.secondFactor}
                        onChange={handleSecondFactorChange}
                        name="secondFactorAuthorization"
                        color="primary"
                      />
                    }
                    label="Second Factor Authorization"
                  />
                </Grid>
              )}

              {prerequisitesData?.feature &&
                prerequisitesData?.feature.map((feature) => {
                  if (!feature.enabled) return undefined;
                  return (
                    <Grid item key={feature.id}>
                      {feature.id === "signature" && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newOrder.options.signature}
                              onChange={handleSignatureChange}
                              name="signature"
                              color="primary"
                            />
                          }
                          label="Signature"
                        />
                      )}

                      {feature.id === "neighbour_delivery" && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newOrder.options.deliverToNeighbours}
                              onChange={handleDeliverToNeighboursChange}
                              name="deliverToNeighbours"
                              color="primary"
                            />
                          }
                          label="Deliver To Neighbours"
                        />
                      )}
                    </Grid>
                  );
                })}
            </Grid>
            <Grid item style={{ marginTop: theme.spacing(3) }}>
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  marginBottom: theme.spacing(2),
                }}
              >
                Additional Info
              </div>

              <Typography style={{ fontWeight: "bold", fontSize: "13" }}>
                Remarks (optional)
              </Typography>

              <TextField
                data-testid="remarks_field"
                variant="outlined"
                id="remarks"
                fullWidth
                value={newOrder.extras.description}
                InputLabelProps={{
                  classes: {
                    root: styles.labelRoot,
                  },
                }}
                onChange={(e) => handleRemarksChange(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item style={{ marginTop: theme.spacing(4) }}>
              <Typography style={{ fontWeight: "bold", fontSize: "13" }}>
                Reference (optional)
              </Typography>

              <Controller
                name="extras.externalId"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    data-testid="reference_field"
                    variant="outlined"
                    id="externalId"
                    fullWidth
                    value={value || ""}
                    onChange={(e) => {
                      onChange(e.target.value);
                      handleReferenceNumber(e.target.value);
                    }}
                    inputProps={{
                      onKeyPress: (e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      },
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            {!!prerequisitesData?.insurances.length && (
              <Grid item style={{ marginTop: theme.spacing(3) }}>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 700,
                    marginBottom: theme.spacing(2),
                  }}
                >
                  Extras
                </div>
                <RetailerInsurances
                  insurances={prerequisitesData?.insurances}
                  selectedInsurance={newOrder.options.selectedIncurance}
                  chooseActiveInsurance={chooseActiveInsurance}
                />
              </Grid>
            )}
          </Grid>
        </>
      </form>
    );
  };
  const renderCreatedOrder = () => {
    return (
      <Paper
        elevation={3}
        sx={{
          p: 3,
          height: "100%",
          width: "75%", // Adjust width directly here
          margin: "0 auto", // Center-align the paper
        }}
      >
        <div style={{ fontSize: 20, fontWeight: 600, padding: "20 0 0 20" }}>
          Bring it on! We're on our way
        </div>
        <div style={{ display: "flex", marginLeft: "13px" }}>
          <ShippingLabelButton
            trackingCode={createdOrder.trackingCode}
            label="Click to download shipping label"
          />
        </div>
        <Divider />
        <Grid
          container
          item
          sx={{
            ...styles.containerRequestedItem,
            width: "100%", // Adjust grid width as necessary
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>
              <IconPickUpRound />
            </div>
            <div>
              <div style={{ fontSize: 20, fontWeight: 600 }}>Pick Up</div>
              {createdOrder.productName && (
                <div style={{ fontSize: 14, color: "#7F8FA4" }}>
                  {createdOrder.productName}
                </div>
              )}
              {createdOrder.retailerName && (
                <div style={{ fontSize: 14 }}>{createdOrder.retailerName}</div>
              )}
            </div>
          </div>
        </Grid>
        <Grid
          item
          sx={{
            ...styles.containerRequestedItem,
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>
              <IconDropOf />
            </div>
            <div>
              <div style={{ fontSize: 20, fontWeight: 600 }}>Drop Off</div>
              {createdOrder.recipientName && (
                <div style={{ fontSize: 14 }}>{createdOrder.recipientName}</div>
              )}
              {createdOrder.recipientNumber && (
                <div style={{ fontSize: 14, color: "#7F8FA4" }}>
                  {createdOrder.recipientNumber}
                </div>
              )}
              {createdOrder.postcode && (
                <div style={{ fontSize: 14, color: "#7F8FA4" }}>
                  {createdOrder.postcode}
                </div>
              )}
              {createdOrder.street && (
                <div style={{ fontSize: 14, color: "#7F8FA4" }}>
                  {createdOrder.street}
                </div>
              )}
              {createdOrder.houseAddition && (
                <div style={{ fontSize: 14, color: "#7F8FA4" }}>
                  {createdOrder.houseAddition}
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Paper>
    );
  };

  const deliverySummaryAndMap = () => {
    if (newOrder.createdDelivery?.status === "success") {
      return null;
    }
    return (
      <div style={styles.rightContainerStyle}>
        <Paper
          elevation={3}
          sx={{
            p: 3,
            width: "100%",
            height: "auto",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%",
              height: "200px",
              marginBottom: theme.spacing(2),
            }}
          >
            <OrderMap
              markerPickup={{
                lat: newOrder.selectedLocation?.coordinates?.latitude,
                lng: newOrder.selectedLocation?.coordinates?.longitude,
              }}
              markerDropOff={{
                lat: newOrder.address.dropOfCoordinates?.lat,
                lng: newOrder.address.dropOfCoordinates?.lng,
              }}
              isTracking={true}
            />
          </div>
          <DeliverySummary stepsStatus />
          <Button
            data-testid="request_delivery_btn"
            type="submit"
            rounded={50}
            fullWidth
            disabled={isCreatingOrder || !isValid || areSomeStepsInvalid}
            variant="contained"
            sx={styles.buttonSubmit}
          >
            {isCreatingOrder ? (
              <CircularProgress size={20} sx={styles.circleProgress} />
            ) : (
              "Request Delivery"
            )}
          </Button>
        </Paper>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          alignItems: "flex-start",
        }}
      >
        {/* Left Section */}
        <Box
          sx={{
            flex: 2,
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {newOrder.createdDelivery?.status === "success" ? (
            renderCreatedOrder()
          ) : (
            <Paper elevation={3} sx={{ p: 3, height: "100%" }}>
              {renderNewDeliveryForm()}
            </Paper>
          )}
        </Box>

        {/* Right Section */}
        {newOrder.createdDelivery?.status !== "success" && (
          <Box
            sx={{
              flex: 1,
              width: "100%",
              maxWidth: { xs: "100%", md: "40%" },
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Paper elevation={3} sx={{ p: 3, height: "100%" }}>
              {deliverySummaryAndMap()}
            </Paper>
          </Box>
        )}
      </Box>
    </form>
  );
}
export default NewDeliveryOrder;
