import React from "react";

import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import CustomPieChartLabel from "./CustomPieChartLabel";

const CustomPieChart = ({ data, centerText }) => {
  return (
    <>
      <ResponsiveContainer width="100%" aspect={2}>
        <PieChart width={600} height={600}>
          <Pie
            key={Math.random()}
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={120}
            label={<CustomPieChartLabel centerText={centerText} />}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};
export default CustomPieChart;
