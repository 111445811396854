import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./css/normalize.css";
import * as serviceWorker from "./serviceWorker";
import { initSentry } from "common-utils/utils/sentry";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./Main";
import RetailerApp from "./RetailerApp";
import Layout from "./Layout";
import NoAuthLayout from "./NoAuthLayout";
import RetailerOrders from "./pages/RetailerOrders";
import NewDelivery from "./pages/NewDelivery";
import RetailerBigdataTest from "./pages/RetailerBigdataTest";
import RetailerBigdata from "./pages/RetailerData";
import Claims from "./pages/Claims";
import RetailerLocations from "./pages/RetailerLocations";
import {
  ForgotPassword,
  ResetPassword,
  SignIn,
} from "@shared/components/lib/index";

initSentry();

// Define your routes array
const routes = [
  {
    element: <RetailerApp />,
    children: [
      {
        element: <NoAuthLayout />,
        children: [
          { path: "/forgot-password/:token?", element: <ForgotPassword /> },
          { path: "/reset-password/:token?", element: <ResetPassword /> },
          { path: "/login", element: <SignIn /> },
        ],
      },
      {
        element: <Layout />,
        children: [
          { path: "/", element: <RetailerOrders /> },
          { path: "/orders", element: <RetailerOrders /> },
          { path: "/delivery", element: <NewDelivery /> },
          // { path: "/bigdatatest", element: <RetailerBigdataTest /> },
          { path: "/claims", element: <Claims /> },
          { path: "/locations", element: <RetailerLocations /> },
          { path: "/overview", element: <RetailerBigdata /> },
          { path: "/benchmarks", element: <RetailerBigdata /> },

          { path: "*", element: <RetailerOrders /> },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes, {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Main>
    <RouterProvider router={router} />
  </Main>,
);

serviceWorker.unregister();
