import React, { useState, useEffect } from "react";
import { Filters, MainBox } from "@shared/components/lib/index";
import { getRequestUI } from "common-utils/utils/api";
import { DateTime } from "luxon";
import CustomPieChart from "../components/CustomPieChart";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useQuery } from "react-query";
const RetailerData = (userClaims) => {
  const date = new Date();
  const [selectedStartPickUpDateFilter, setSelectedStartPickUpDateFilter] =
    useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [selectedEndPickUpDateFilter, setSelectedEndPickUpDateFilter] =
    useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  const nextDayTotalDelveriesColor = "#228b22";
  const sameDayTotalDelveriesColor = "#3f51b5";
  const expressTotalDelveriesColor = "#f70000";
  const analytics = useQuery(["analytics"], getAnalytics);

  useEffect(() => {
    analytics.refetch();
  }, [selectedStartPickUpDateFilter, selectedEndPickUpDateFilter]);

  function getAnalytics() {
    return getRequestUI("/retailer/data", getFilterData());
  }
  const getFilterData = () => ({
    startDate: DateTime.fromJSDate(selectedStartPickUpDateFilter).toFormat(
      "yyyy-MM-dd"
    ),
    endDate: DateTime.fromJSDate(selectedEndPickUpDateFilter).toFormat(
      "yyyy-MM-dd"
    ),
  });

  const getPieData = (data) => {
    return [
      {
        name: "Next Day",
        value: data.deliveryType.nextDayTotalDeliveries,
        color: nextDayTotalDelveriesColor,
      },
      {
        name: "Same Day",
        value: data.deliveryType.sameDayTotalDeliveries,
        color: sameDayTotalDelveriesColor,
      },
      {
        name: "Express",
        value: data.deliveryType.expressTotalDeliveries,
        color: expressTotalDelveriesColor,
      },
    ];
  };

  return (
    <>
      <MainBox>
        <div style={{ padding: 20 }}>
          <Filters
            setTitle={"Data"}
            selectedStartPickUpDateFilter={selectedStartPickUpDateFilter}
            setSelectedStartPickUpDateFilter={setSelectedStartPickUpDateFilter}
            selectedEndPickUpDateFilter={selectedEndPickUpDateFilter}
            setSelectedEndPickUpDateFilter={setSelectedEndPickUpDateFilter}
            hideApplyFilter={true}
            hideExport={true}
          />
        </div>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={3}>
            <Typography variant="h3">General</Typography>
          </Grid>
        </Grid>
        {!analytics.isLoading && !analytics.error && (
          <CustomPieChart
            centerText={{
              title: "Total Orders",
              value: analytics.data ? analytics.data.totalDeliveries : "",
            }}
            data={getPieData(analytics.data)}
          />
        )}
      </MainBox>
    </>
  );
};

export default RetailerData;
