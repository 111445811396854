// Layout.js
import { Outlet, useOutletContext } from "react-router-dom";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { PageContainer } from "@toolpad/core/PageContainer";
import { Navigate } from "react-router-dom";

export default function Layout() {
  const { auth, userClaims } = useOutletContext() || {};

  const isUser =
    userClaims?.userType === "retailer" ||
    userClaims?.userType === "storeManager";

  if (!auth || !isUser) {
    return <Navigate to="/login" replace />;
  }

  return (
    <DashboardLayout>
      <PageContainer breadcrumbs={""} title={""}>
        <Outlet context={{ auth, userClaims }} />
      </PageContainer>
    </DashboardLayout>
  );
}
