// NoAuthLayout.js
import { Outlet, useOutletContext } from "react-router-dom";
import { Navigate } from "react-router-dom";

export default function NoAuthLayout() {
  const { auth, userClaims } = useOutletContext() || {};

  const isUser =
    userClaims?.userType === "retailer" ||
    userClaims?.userType === "storeManager";

  if (auth && isUser) {
    return <Navigate to="/orders" replace />;
  }

  return <Outlet context={{ auth, userClaims }} />;
}
