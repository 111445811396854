import { useLocation } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import NewDeliveryOrder from "./NewDeliveryOrder";

const NewDelivery = () => {
  const location = useLocation();

  return <NewDeliveryOrder key={location.key} />;
};

export default NewDelivery;
